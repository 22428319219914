import ActionButton from "@components/shared/ActionButton";
import { H4 } from "@components/Typo";
import * as React from "react";
import { BUSINESS_REGISTER_FORM_DOM_ID } from "../../config/constants";
import styled from "styled-components";
import SectionContainer from "../SectionContainer";

const DownloadDocument = () => {
  return (
    <SectionContainer size="medium">
      <Wrapper>
        <MsgWrapper>
          <H4>＼今すぐ無料で導入！／</H4>
        </MsgWrapper>
        <ButtonWrapper>
          <a href={`#${BUSINESS_REGISTER_FORM_DOM_ID}`}>
            <ActionButton>今すぐ申し込む</ActionButton>
          </a>
        </ButtonWrapper>
      </Wrapper>
    </SectionContainer>
  );
};

export default DownloadDocument;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const MsgWrapper = styled.div`
  margin-bottom: 7px;
  text-align: center;
  letter-spacing: 3px;
`;
